//import { act } from '@testing-library/react';
import React from 'react';
import { useEffect, useRef, useState  } from 'react';
import emailjs from '@emailjs/browser';
import myimage from './assest/images/my-image.jpg';
import webdev from './assest/images/app-development.png';
import react from './assest/images/physics.png';
import wordpress from './assest/images/wordpress.png';
import uxui from './assest/images/ui-design.png';
import plc from './assest/images/plc.jpg';
import cbsl from './assest/images/cbsl.jpg';
import cccc from './assest/images/cccc.jpg';
import pcassizt from './assest/images/pcaasizt.jpg';
import slithm from './assest/images/slithm.jpg';
import dilrukshi from './assest/images/dilrukshi.jpeg';
import lokesha from './assest/images/lokeesha.jpg';
import oshanka from './assest/images/chamod.jpg';
import ieeee from './assest/images/ieee.png';
import plcproject from './assest/images/plcproject.jpg';
import flycatch from './assest/images/flycatcher.png';
import cbslpro from './assest/images/cbsl-web.jpg';
import slithmpro from './assest/images/slithm.png';
import branch from './assest/images/branchloc.png';
import efuau from './assest/images/efutures.png';
import contactplug from './assest/images/contact-plugin.jpg';
import research from './assest/images/research.jpg';
import cahtbot from './assest/images/chatbot.jpg';
import webper from './assest/images/website-performance.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    AOS.init();
    const [activePage, setActivePage] = useState('about');
    const handleNavLinkClick = (newPage) => {
      //console.log(`New page: ${newPage}`);
      setActivePage(newPage);
    }
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {  
      setIsActive((prevIsActive) => !prevIsActive);
    };
    const [isActiveport, setIsActivePort] = useState(false);
    const handleClickPort = () => {  
      setIsActivePort((isActiveport) => !isActiveport);
    };

    const [portfolioActive, setportfolioActive] = useState('all');
    const handlePortfolio =(newClick)=>{
      setportfolioActive(newClick);
    }
   useEffect(()=>{
    const web_website = document.querySelectorAll('[data-category="websites"]');
    const web_application = document.querySelectorAll('[data-category="webapplication"]');
    const web_pluginsthemes = document.querySelectorAll('[data-category="webplugintheme"]');
    if(portfolioActive ==='createdwebsite'){
      web_website.forEach((web_website)=>{
        web_website.classList.add('active');
      });
      web_application.forEach((web_application)=>{
        web_application.classList.remove('active');
      });
      web_pluginsthemes.forEach((web_pluginsthemes)=>{
        web_pluginsthemes.classList.remove('active');
      });
    }
    else if(portfolioActive ==='webapp'){
       web_application.forEach((web_application)=>{
         web_application.classList.add('active');
       });
       web_pluginsthemes.forEach((web_pluginsthemes)=>{
        web_pluginsthemes.classList.remove('active');
      });
       web_website.forEach((web_website)=>{
        web_website.classList.remove('active');
      });

     }
     else if(portfolioActive ==='plugin'){
      web_pluginsthemes.forEach((web_pluginsthemes)=>{
        web_pluginsthemes.classList.add('active');
      });
      web_application.forEach((web_application)=>{
        web_application.classList.remove('active');
      });
      web_website.forEach((web_website)=>{
        web_website.classList.remove('active');
      });  
     }
    else{
       web_application.forEach((web_application)=>{
         web_application.classList.add('active');
       });
       web_pluginsthemes.forEach((web_pluginsthemes)=>{
         web_pluginsthemes.classList.add('active');
       });
       web_website.forEach((web_website)=>{
        web_website.classList.add('active');
      });
    }

   },[portfolioActive]);
   
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
     
  
      emailjs.sendForm('service_l1es7sf', 'template_9yrcdmj', form.current, 'd-v3Z8zCjdoauDCji')
        .then((result) => {
            alert('Mail Sent Successfully!)');
            e.target.reset();
        }, (error) => {
          alert('There might be a problem in sending mail, Please put a mail to : induvara.amarasekara@gmail.com)');
        });
    };

    return (
      <div className="main-wrapper"> 

      <div className={`sidebar ${isActive ? 'active' : ''}`}>
        <aside data-sidebar>
        <div className="sidebar-info">
          <figure className="avatar-box" data-aos="zoom-out"
                  data-aos-offset="200">
            <img
              style={{borderRadius:'50%'}}
              src={myimage}
              alt="Mahima Induvara"
              width="80"
            />
          </figure>

          <div className="info-content">
            <h1 className="name" title="Mahima Induvara">Mahima Induvara</h1>

            <p className="title"> FUll-Stack Web Developer </p>
          </div>

          <button className="info_more-btn" onClick={handleClick}>
            <span>Show Contacts</span>

            <ion-icon name="chevron-down"></ion-icon>
          </button>
        </div>

        <div className="sidebar-info_more">
          <div className="separator"></div>

          <ul className="contacts-list">
            <li className="contact-item" data-aos="zoom-in"
                data-aos-offset="100">
              <div className="icon-box">
                <ion-icon name="mail-outline"></ion-icon>
              </div>

              <div className="contact-info">
                <p className="contact-title">Email</p>

                <a href="mailto:induvara.amarasekara@gmail.com" className="contact-link">induvara.amarasekara@gmail.com
                </a> 
              </div>
            </li>

            <li className="contact-item" data-aos="zoom-in"
                data-aos-offset="100">
              <div className="icon-box">
                <ion-icon name="phone-portrait-outline"></ion-icon>
              </div>

              <div className="contact-info">
                <p className="contact-title">Phone</p>

                <a href="tel:+94774166098" className="contact-link">+94 77 416 6098</a>
                <a href="tel:+9472858432" className="contact-link">+94 72 858 4321</a>
              </div>
            </li>

            <li className="contact-item" data-aos="zoom-in"
                data-aos-offset="100">
              <div className="icon-box">
                <ion-icon name="calendar-outline"></ion-icon>
              </div>

              <div className="contact-info">
                <p className="contact-title">Birthday</p>

                <time>1997-12-05</time>
              </div>
            </li>

            <li className="contact-item" data-aos="zoom-in"
                data-aos-offset="100">
              <div className="icon-box">
                <ion-icon name="location-outline"></ion-icon>
              </div>

              <div className="contact-info">
                <p className="contact-title">Location</p>

                <address>No:360/D7 Pelenwatte, Pannipitiya</address>
              </div>
            </li>
          </ul>

          <div className="separator"></div>

          <ul className="social-list">
            <li className="social-item">
              <a href="https://www.facebook.com/mahima.amarasekara" target="_blank" rel="noopener noreferrer" className="social-link">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li>

            <li className="social-item">
              <a href="https://www.instagram.com/_induvaa_/" target="_blank" rel="noopener noreferrer" className="social-link">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

            <li className="social-item">
              <a href="https://www.linkedin.com/in/induvara-amarasekara-14363a1a2/" target="_blank" rel="noopener noreferrer" className="social-link">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>
            </li>

            <li className="social-item">
              <a href="https://github.com/MahimaInduvara97" target="_blank" rel="noopener noreferrer" className="social-link">
                <ion-icon name="logo-github"></ion-icon>
              </a>
            </li>

             <li className="social-item">
              <a href="https://twitter.com/mahima_induvara" target="_blank" rel="noopener noreferrer" className="social-link">
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
            </li>

          </ul>
        </div>
      </aside>
      </div>
      <div className="main-content">
      <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item">
          <button
            className={`navbar-link ${activePage === 'about' ? 'active' : ''}`}
            data-nav-link
            onClick={() => handleNavLinkClick('about')}
          >
            About
          </button>
        </li>

        <li className="navbar-item">
          <button
             className={`navbar-link ${activePage === 'resume' ? 'active' : ''}`}
            data-nav-link
            onClick={() => handleNavLinkClick('resume')}
          >
            Resume
          </button>
        </li>
        <li className="navbar-item">
          <button
             className={`navbar-link ${activePage === 'portfolio' ? 'active' : ''}`}
            data-nav-link
            onClick={() => {handleNavLinkClick('portfolio')}}
          >
            Portfolio
          </button>
        </li>
        <li className="navbar-item">
          <button
            className={`navbar-link ${activePage === 'publications' ? 'active' : ''}`}
            data-nav-link
            onClick={() => {handleNavLinkClick('publications')}}
          >
            Articles
          </button>
        </li>
        <li className="navbar-item">
          <button
            className={`navbar-link ${activePage === 'contact' ? 'active' : ''}`}
            data-nav-link
            onClick={() => {handleNavLinkClick('contact')}}
          >
            Contact
          </button>
            </li>
          </ul>
        </nav>


      <article className={`about ${activePage === 'about' ? 'active' : ''}`}>
        <header>
            <h2 className="h2 article-title">About me</h2>
          </header>

          <section className="about-text">
            <p>
            As a dedicated and results-driven full-stack web developer with over 3 years of specialized experience in WordPress development, I bring a passion for excellence to every project. Known for my reliability, adaptability, and ability to thrive under pressure, I consistently meet deadlines and exceed expectations.
            </p>
            <p>
            With excellent interpersonal skills and a proactive approach, I excel in collaborative environments, actively contributing to team success. Eager to learn and grow, I embrace new challenges and seek opportunities to expand my skill set. Committed to delivering top-notch work, I bring a dynamic and decisive mindset to every project.
            </p>
            <p>Other Skills:&nbsp; Photograpy, Creative Writting</p>
          </section>
          <section className="service">
            <h3 className="h3 service-title">What I'm doing </h3>

            <ul className="service-list">
              <li className="service-item" data-aos="zoom-in">
                <div className="service-icon-box">
                  <img
                    src={webdev}
                    alt="Application development icon"
                    width="40"
                  />
                </div>

                <div className="service-content-box">
                  <h4 className="h4 service-item-title">Application Development</h4>

                  <p className="service-item-text">
                    Develop Web application usign Laravel/CodeIgniter Framework
                  </p>
                </div>
              </li>

              <li className="service-item" data-aos="zoom-in"
     >
                <div className="service-icon-box">
                  <img
                    src={wordpress}
                    alt="wordpress icon"
                    width="40"
                  />
                </div>

                <div className="service-content-box">
                  <h4 className="h4 service-item-title">WordPress Development</h4>

                  <p className="service-item-text">
                    Design and Develop Fully Websites/Themes/Plugins</p>
                </div>
              </li>

              <li className="service-item" data-aos="zoom-in"
     >
                <div className="service-icon-box">
                  <img
                    src={react}
                    alt="react icon"
                    width="40"
                  />
                </div>

                <div className="service-content-box">
                  <h4 className="h4 service-item-title">React Frontend</h4>

                  <p className="service-item-text">
                    Good at React Frontend Developments (Intermediate)
                  </p>
                </div>
              </li>

              <li className="service-item" data-aos="zoom-in"
     >
                <div className="service-icon-box">
                  <img
                    src={uxui}
                    alt="UX-UI icon"
                    width="40"
                  />
                </div>

                <div className="service-content-box">
                  <h4 className="h4 service-item-title">UX-UI Design</h4>

                  <p className="service-item-text">
                   Creating Wireframes & Mockups using Figma, Photoshop, Adobe xd
                  </p>
                </div>
              </li>
            </ul>
          </section>
          <section className="skill">
            <h3 className="h3 skills-title">Tools & Technologies</h3>
            <ul className="timeline-list">
              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="50">
                <h4 className="h4 timeline-item-title">
                  <strong>Front-end :</strong> 
                  <div className="timeline-text" style={{display: 'inline'}}> React , HTML5, CSS, JavaScript</div>
                </h4>
              </li>
    
              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="100">
                <h4 className="h4 timeline-item-title">
                  <strong>Frameworks :</strong> 
                  <div className="timeline-text"  style={{display: 'inline'}}> Node.js, Laravel </div>
                </h4>
              </li>

              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="200">
                <h4 className="h4 timeline-item-title">
                  <strong>Programming :</strong> 
                  <div className="timeline-text"  style={{display: 'inline'}}> Javascript , PHP , Python  </div>
                </h4>
              </li>

              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="300">
                <h4 className="h4 timeline-item-title">
                  <strong>Dev Ops :</strong> 
                  <div className="timeline-text"  style={{display: 'inline'}}> EC2(AWS), Bitnami , Siteground </div>
                </h4>
              </li>

              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="400">
                <h4 className="h4 timeline-item-title">
                  <strong>Version Controls :</strong> 
                  <div className="timeline-text"  style={{display: 'inline'}}> Github , Bitbucket </div>              
                </h4>
              </li>

              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="500">
                <h4 className="h4 timeline-item-title">
                  <strong>Database :</strong> 
                  <div className="timeline-text"  style={{display: 'inline'}}> MySQl, SQLServer, MongoDB </div>              
                </h4>
              </li>

              <li className="timeline-item" data-aos="fade-out"
     data-aos-offset="500">
                <h4 className="h4 timeline-item-title">
                  <strong>Architecures :</strong> 
                  <div className="timeline-text"  style={{display: 'inline'}}> MVC  </div>              
                </h4>
              </li>
            </ul>
          </section><br></br>
          <section className="clients">
            <h3 className="h3 clients-title">Featured Clients</h3>

            <ul className="clients-list has-scrollbar">
              <li className="clients-item">
                <a href="https://www.plc.lk/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={plc}
                    alt="client logo"
                  />
                </a>
              </li>

              <li className="clients-item">
                <a href="https://cbs.cbsl.lk/t" target="_blank" rel="noopener noreferrer">
                  <img
                    src={cbsl}
                    alt="client logo"
                  />
                </a>
              </li>

              <li className="clients-item">
                <a href="https://www.slithm.edu.lk" target="_blank" rel="noopener noreferrer">
                  <img
                    src={slithm}
                    alt="client logo"
                  />
                </a>
              </li>

              <li className="clients-item">
                <a href="https://cccc.lk/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={cccc}
                    alt="client logo"
                  />
                </a>
              </li>

              <li className="clients-item">
                <a href="https://pcassizt.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={pcassizt}
                    alt="client logo"
                  />
                </a>
              </li>

            </ul><br />


    

          <section className="testimonials">
            <h3 className="h3 testimonials-title">Recommendations</h3>

            <ul className="testimonials-list has-scrollbar">
              <li className="testimonials-item">
                <div className="content-card" data-aos="zoom-in"
                  data-aos-offset="200">
                  <figure className="testimonials-avatar-box">
                    <img
                      src={dilrukshi}
                      alt="Ratnaweera"
                      width="60"
                      data-testimonials-avatar
                    />
                  </figure>

                  <h4
                    className="h4 testimonials-item-title"
                    data-testimonials-title > Mrs. S D Ratnaweera
                  </h4>
                  <h6 className="h6 service-item-title">Senior Assistant Director, Center for Banking Studies </h6>

                  <div className="testimonials-text" data-testimonials-text>
                    <p>
                      Mahima comes with a different way of taking on a task. Very much a task master ,he has no qualms in forcing the issue and get the team rolling. Not the type who may be drawn in to long sessions in planning ,he's more suited to environments where the dynamics are fast changing so the decisions require a reasonably high degree of instinct as well as direction from the front.He appears to thrive on the energy of the task and less on the routine .
                    </p>
                  </div>
                </div>
              </li>

              <li className="testimonials-item">
                <div className="content-card" data-aos="zoom-in"
                data-aos-offset="200">
                  <figure className="testimonials-avatar-box">
                    <img
                      src={lokesha}
                      alt="Weerasinghe"
                      width="60"
                      data-testimonials-avatar
                    />
                  </figure>

                  <h4
                    className="h4 testimonials-item-title"
                    data-testimonials-title
                  >
                    Mrs. L Weerasinghe
                  </h4>
                  <h6 className="h6 service-item-title">Lecturer/ Research Supervisor | SLIIT</h6>


                  <div className="testimonials-text" data-testimonials-text>
                    <p>
                    Mahima is one of my student in undergraduate. He is a hardworking and dedicated person. I closely worked with Mahima in their final year research project. He has done an amazing job with thier project and the project has been selected to compete in Microsoft Imagine Cup world finals. Also, he has shown excellent performance in all the subjects in his undergraduate studies.
                      </p>
                  </div>
                </div>
              </li>

              <li className="testimonials-item">
                <div className="content-card" data-testimonials-item>
                  <figure className="testimonials-avatar-box">
                    <img
                      src={oshanka}
                      alt="Chamod"
                      width="60"
                      data-testimonials-avatar
                    />
                  </figure>

                  <h4
                    className="h4 testimonials-item-title"
                    data-testimonials-title
                  >
                    Mr. Chamod Oshanka
                  </h4>
                  <h6 className="h6 service-item-title">Founder| FlycatcherTrils (PVT) LYD</h6>

                  <div className="testimonials-text" data-testimonials-text>
                    <p>
                    Mahima created our website by talking to us often and asking our opinions. The service was given to us in very accordare with the money and it's very respect. We highly recommend him.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </section>

          {/* <div className="modal-container" data-modal-container>
            <div className="overlay" data-overlay></div>

            <section className="testimonials-modal">
              <button className="modal-close-btn" data-modal-close-btn>
                <ion-icon name="close-outline"></ion-icon>
              </button>

              <div className="modal-img-wrapper">
                <figure className="modal-avatar-box">
                  <img
                    src="./assets/images/avatar-1.png"
                    alt=""
                    width="80"
                    data-modal-img
                  />
                </figure>

                <img src="./assets/images/icon-quote.svg" alt="quote icon" />
              </div>

              <div className="modal-content">
                <h4 className="h3 modal-title" data-modal-title>Daniel lewis</h4>
                <div data-modal-text>
                  <p>
                    Richard was hired to create a corporate identity. We were
                    very pleased with the work done. She has a lot of experience
                    and is very concerned about the needs of client. Lorem ipsum
                    dolor sit amet, ullamcous cididt consectetur adipiscing
                    elit, seds do et eiusmod tempor incididunt ut laborels
                    dolore magnarels alia.
                  </p>
                </div>
              </div>
            </section>
          </div> */}
          </section>
        </article>

        <article className={`resume ${activePage === 'resume' ? 'active' : ''}`}>
          <header>
            <h2 className="h2 article-title">Resume</h2>
          </header>

          <section className="timeline">
            <div className="title-wrapper">
              <div className="icon-box">
                <ion-icon name="book-outline"></ion-icon>
              </div>

              <h3 className="h3">Professional Experience</h3>
            </div>

            <ol className="timeline-list">
              <li className="timeline-item">
                <h4 className="h4 timeline-item-title">Full-stack Web Developer <br />
                EFutures (Pvt) Ltd, No:20/54, Fairfield Gardens, Borella, Colombo 08.</h4>

                <span>05/11/2021 – Present</span>

                 <div className="timeline-text">
                  <ul className="bullet-points">
                  <li>Built and maintained the People's Leasing and Finance website.</li>
                  <li>Developed additional features and functionalities for the website.</li>
                  <li>Won a Brown medal for the design and development of the plc.lk website.</li>
                  <li>Built the Efuture (company) website and enhanced its performance.</li>
                  <li>Learned basic knowledge about React from senior colleagues at the company.</li>
                  <li>Removed various malware affecting the WordPress website.</li>
                  <li>Developed a custom mail management plugin.</li>
                  <li>Modified a Branch Locator plugin for improved user experience.</li>
                  <li>Designed and implemented a custom theme for a unique look and feel.</li>
                  </ul>
                  </div>
              </li>

              <li className="timeline-item">
                <h4 className="h4 timeline-item-title">Associate Web & Sowftwere Developer<br/>
                EArrow (Pvt) Ltd, Paramount Tower 192/10, Nawala Rd, 10250.</h4>

                <span>15/10/2020 - 07/10/2021</span>

                <div className="timeline-text">
                <ul className="bullet-points">
                  <li>Worked as a web and software developer.</li>
                  <li>Participated in client Business Analysis (BA) sessions to gather requirements.</li>
                  <li>Designed mockups and built the entire website for CBSl.</li>
                  <li>Contributed to the SLITHM Project by developing a report management system.</li>
                  <li>Developed a curriculum management for SLITHM.</li>
                  <li>Prepared project-related documents, including USER MANUALS.</li>
                  <li>Designed artwork for company marketing using Photoshop.</li>
                </ul>
                </div>
              </li>

               <li className="timeline-item">
                <h4 className="h4 timeline-item-title">Trainee Web Developer<br/>
                MADZoo Digital (Pvt) Ltd, No:50/10/11, Kohuwala</h4>

                <span>09/2/2020 – 01/10/2020</span>

                <div className="timeline-text">
                <ul className="bullet-points">
                    <li>Completed development tasks as a Web Developer.</li>
                    <li>Technologies : Laravel, WordPress, PHP, MySql</li>
                    <li>Participated in client meetings to identify client requirements.</li>
                  </ul>
                  </div>
              </li>
            </ol>
          </section>

          <section className="timeline">
            <div className="title-wrapper">
              <div className="icon-box">
                <ion-icon name="book-outline"></ion-icon>
              </div>

              <h3 className="h3">Education</h3>
            </div>

            <ol className="timeline-list">
              <li className="timeline-item">
                <h4 className="h4 timeline-item-title">
                  BSc (Hons) in Information Technology Specialized in Information Technology
                </h4>

                <span>2018 - 2022</span>

                <div className="timeline-text">
                <ul className="bullet-points">
                  <li>Sri Lanka Institute of Information Technology</li>
                </ul>
                </div>
              </li>

              <li className="timeline-item">
                <h4 className="h4 timeline-item-title">KET Exam </h4>

                <span>2017</span>

                <div className="timeline-text">
                <ul className="bullet-points">
                   <li>British Counsil </li>
                 </ul>
                </div>
              </li>

              <li className="timeline-item">
                <h4 className="h4 timeline-item-title">
                  Secondary Education
                </h4>

                <span>2009 - 2017</span>

                <div className="timeline-text">
                <ul className="bullet-points">
                  <li>Isipathana College Colombo, Sri Lanka </li>
                  <li>GCE A/L (2017) - Passed in Biology Science stream with CSS </li>
                  <li>GCE O/L (2015) - Passed with 7A B & C </li>
                </ul>
              </div>
              </li>

            </ol>
          </section>

          <section className="Achievements">
              <h3 className="h3 Achievements-title">Achievements</h3>

              <ul className="Achievements-list has-scrollbar">
                <li className="Achievements-item">
                  <ul className="project-list">
                    <li
                      className="project-item active"
                      data-filter-item
                      data-category="web development">

                      <a href="https://www.linkedin.com/feed/update/urn:li:activity:6910512759046643712/" target="_blank" rel="noopener noreferrer">
                        <figure className="project-img">
                          <div className="project-item-icon-box">
                            <ion-icon name="eye-outline"></ion-icon>
                          </div>

                          <img
                            src={ieeee}
                            alt="finance"
                            loading="lazy"
                          />
                        </figure>

                        <h3 className="project-title">2021 IEEE Research Paper Author Certificate</h3>

                        <p className="project-category">IEEE IEMCON Canada</p>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
          </section>


          <section className="skill">
            <h3 className="h3 skills-title">Key skills</h3>

            <ol className="skills-list content-card">
              <li className="skills-item">
                <div className="title-wrapper">
                  <h5 className="h5">Design Proficiency</h5>
                  <data value="95">95%</data>
                </div>

                <div className="skill-progress-bg">
                  <div className="skill-progress-fill" style={{width: '95%'}}></div>
                </div>
              </li>
              <li className="skills-item">
                <div className="title-wrapper">
                  <h5 className="h5">WordPress Frontend/Backend Mastery</h5>
                  <data value="90">90%</data>
                </div>

                <div className="skill-progress-bg">
                  <div className="skill-progress-fill" style={{width: '90%'}}></div>
                </div>
              </li>
              
              <li className="skills-item">
                <div className="title-wrapper">
                  <h5 className="h5">React Frontend Experince</h5>
                  <data value="65">65%</data>
                </div>

                <div className="skill-progress-bg">
                  <div className="skill-progress-fill" style={{width: '65%'}}></div>
                </div>
              </li>
              <li className="skills-item">
                <div className="title-wrapper">
                  <h5 className="h5">Problem Solving & Debugging</h5>
                  <data value="93">93%</data>
                </div>

                <div className="skill-progress-bg">
                  <div className="skill-progress-fill" style={{width: '93%'}}></div>
                </div>
              </li>
              <li className="skills-item">
                <div className="title-wrapper">
                  <h5 className="h5">Overall Development Excellence</h5>
                  <data value="90">90%</data>
                </div>

                <div className="skill-progress-bg">
                  <div className="skill-progress-fill" style={{width: '90%'}}></div>
                </div>
              </li>
              

            </ol>

           <section className="skill">
            <h3 className="h3 skills-title">Download CV</h3>

            <ul className="skills-list content-card">
                <li className="skills-item">
                    <div className="title-wrapper">
                        <a href='https://codenexus.live/wp-content/uploads/2023/12/Mahima-Induvara.pdf' className="download-button" target="_blank" rel="noopener noreferrer">Mahima Curriculum Vitae</a>
                    </div>
                </li>
            </ul>
            </section>
        </section>
    </article>

        <article className={`portfolio ${activePage === 'portfolio' ? 'active' : ''}`}>
          <header>
            <h2 className="h2 article-title">Portfolio</h2>
          </header>

          <section className="projects">
            <ul className="filter-list">
              <li className="filter-item">
                <button onClick={()=>{handlePortfolio('all')}} className={`${portfolioActive === 'all' ? 'active' : ''}`}>All</button>
              </li>

              <li className="filter-item">
                <button onClick={()=>{handlePortfolio('createdwebsite')}} className={`${portfolioActive === 'createdwebsite' ? 'active' : ''}`}>Web Sites</button>
              </li>

              <li className="filter-item">
                <button onClick={()=>{handlePortfolio('webapp')}} className={`${portfolioActive === 'webapp' ? 'active' : ''}`}>Web Application</button>
              </li>

              <li className="filter-item">
                <button onClick={()=>{handlePortfolio('plugin')}} className={`${portfolioActive === 'plugin' ? 'active' : ''}`}>Plugin/Theme Development</button>
              </li>
            </ul>

            <div className="filter-select-box">
              <button className={`filter-select ${isActiveport ? 'active' : ''}`} onClick={handleClickPort}>
                <div className="select-value" data-selecct-value>
                  Select category
                </div>

                <div className="select-icon">
                  <ion-icon name="chevron-down"></ion-icon>
                </div>
              </button>

              <ul className="select-list">
                <li className="select-item">
                  <button data-select-item>All</button>
                </li>

                <li className="select-item">
                  <button data-select-item>Web Development</button>
                </li>

                <li className="select-item">
                  <button data-select-item>Web Applications</button>
                </li>

                <li className="select-item">
                  <button data-select-item>Plugin/Theme Development</button>
                </li>
              </ul>
            </div>


            <ul className="project-list">
              <li
                className="project-item"
                data-filter-item
                data-category="websites"
              >
                <a href="https://www.plc.lk/" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={plcproject}
                      alt="plc"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">People's Leasing & Finance</h3>

                  <p className="project-category">Web Development</p>
                </a>
              </li>

              <li
                className="project-item"
                data-filter-item
                data-category="websites"
              >
                <a href="https://flycatchertrails.com/" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={flycatch}
                      alt="Fly Catcher Trails"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">Fly Catcher Trails</h3>

                  <p className="project-category">Web Development</p>
                </a>
              </li>

              <li
                className="project-item"
                data-filter-item
                data-category="webapplication"
              >
                <a href="https://cbs.cbsl.lk/" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={cbslpro}
                      alt="cbsl"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">Center for Banking Studies</h3>

                  <p className="project-category">Web Development</p>
                </a>
              </li>

              <li
                className="project-item"
                data-filter-item
                data-category="websites"
              >
               <a href="https://www.efuturesworld.ca/"> 
                  <figure className="project-img">
                   <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div> 

                    <img
                      src={efuau}
                      alt="EFutures"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">EFutures</h3>

                  <p className="project-category">Web Development</p>
                </a>
              </li>

              <li
                className="project-item"
                data-filter-item
                data-category="webapplication"
              >
                <a href="https://www.slithm.edu.lk/" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={slithmpro}
                      alt="SLITHM"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">SLITHM Dashbord</h3>

                  <p className="project-category">Web Application</p>
                </a>
              </li>

              <li
                className="project-item"
                data-filter-item
                data-category="webplugintheme"
              >
                <a href="https://www.youtube.com/watch?v=h_wUuwl8Q40" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={branch}
                      alt="plugin_dev"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">Branch Locater Plugin</h3>

                  <p className="project-category">Plugin Development</p>
                </a>
              </li>
              <li
                className="project-item"
                data-filter-item
                data-category="webplugintheme"
              >
                <a href="https://www.efuturesworld.us/" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={contactplug}
                      alt="contact_plugin_dev"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">Custom Contact Plugin</h3>

                  <p className="project-category">Plugin Development</p>
                </a>
              </li>
              <li
                className="project-item"
                data-filter-item
                data-category="webplugintheme"
              >
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <ion-icon name="eye-outline"></ion-icon>
                    </div>

                    <img
                      src={efuau}
                      alt="EFutures_Theme"
                      loading="lazy"
                    />
                  </figure>

                  <h3 className="project-title">EFutures Theme</h3>

                  <p className="project-category">Theme Development</p>
                </a>
              </li>
            </ul>
          </section>
        </article>
        <article className={`publications ${activePage === 'publications' ? 'active' : ''}`}>
          <header>
            <h2 className="h2 article-title">Articles</h2>
          </header>

          <section className="publications-posts">
            <ul className="publications-posts-list">
              <li className="publications-post-item">
                <a href="https://www.linkedin.com/posts/induvara-amarasekara-14363a1a2_ieee-research-arapp-activity-6894135450160787456-3-bq?utm_source=share&utm_medium=member_desktop" target="_blank" rel="noopener noreferrer">
                  <figure className="publications-banner-box">
                    <img
                      src={research}
                      alt="Reseach_Paper_IEEE"
                      loading="lazy"
                    />
                  </figure>

                  <div className="publications-content">
                    <div className="publications-meta">
                      <p className="publications-category">Reseach Papers</p>

                      <span className="dot"></span>

                      <time datetime="2022-02-23">Dec 2, 2021</time>
                    </div>

                    <h3 className="h3 publications-item-title">
                      Research Paper Publication  
                    </h3>

                    <p className="publications-text">
                    #IEEE 12th Annual Information Technology, Electronics and Mobile Communication Conference (IEEE IEMCON), Vancouver, Canada
                    </p>
                  </div>
                </a>
              </li>

              <li className="publications-post-item">
                <a href="https://www.linkedin.com/posts/induvara-amarasekara-14363a1a2_wordpress-cache-webdevelopment-activity-6973958521310629888-R0gn?utm_source=share&utm_medium=member_desktop" target="_blank" rel="noopener noreferrer">
                  <figure className="publications-banner-box">
                    <img
                      src={webper}
                      alt="performence_and_optimization"
                      loading="lazy"
                    />
                  </figure>

                  <div className="publications-content">
                    <div className="publications-meta">
                      <p className="publications-category">Blogs</p>

                      <span className="dot"></span>

                      <time datetime="2022-02-23">Mar 20, 2022</time>
                    </div>

                    <h3 className="h3 publications-item-title">Web Performence and Optimization</h3>

                    <p className="publications-text">
                      Importances of Fault Tolerance & Disaster Recovery.
                    </p>
                  </div>
                </a>
              </li>

              <li className="publications-post-item">
                <a href="#chatbot" target="_blank" rel="noopener noreferrer">
                  <figure className="publications-banner-box">
                    <img
                      src={cahtbot}
                      alt="Centiment Analys Chatbot"
                      loading="lazy"
                    />
                  </figure>

                  <div className="publications-content">
                    <div className="publications-meta">
                      <p className="publications-category">Blogs</p>

                      <span className="dot"></span>

                      <time datetime="2022-02-23">Nov 08, 2021</time>
                    </div>

                    <h3 className="h3 publications-item-title">Centiment Analys Chatbot</h3>

                    <p className="publications-text">
                      A study of AI and ML for Develop Chatbot with Centiment Analysis.
                    </p>
                  </div>
                </a>
              </li>

            </ul>
          </section>
          <section className="skill">
            <ul className="skills-list content-card">
                <li className="skills-item">
                    <div className="title-wrapper">
                        <a href="https://codenexus.live/" target="_blank" rel="noopener noreferrer" className="download-button">CodeNexus Freelance</a>
                    </div>
                </li>
            </ul>
        </section>
        </article>

        <article className={`contact ${activePage === 'contact' ? 'active' : ''}`}>
          <header>
            <h2 className="h2 article-title">Contact</h2>
          </header>

          <section className="mapbox" style={{backgroundImage: 'url(https://codenexus.live/wp-content/uploads/2023/12/loacation.png)', backgroundSize:'cover',backgroundPosition:'center'}}>
            <figure>
              
            </figure>
          </section>

          <section className="contact-form">
            <h3 className="h3 form-title">Contact Form</h3>

            <form
              ref={form} onSubmit={sendEmail}
              className="form"
            >
              <div className="input-wrapper">
                <input
                  type="text"
                  name="user_name"
                  className="form-input"
                  placeholder="Full name"
                  required
                />

                <input
                  type="email"
                  name="user_email"
                  className="form-input"
                  placeholder="Email address"
                  required
                />
              </div>

              <textarea
                name="message"
                className="form-input"
                placeholder="Your Message"
              ></textarea>

              <button className="form-btn"  type="submit" value="Send">
                <ion-icon name="paper-plane"></ion-icon>
                <span>Send Message</span>
              </button>
            </form>
          </section>
        </article>
      </div>
    </div>
    );
  };

  export default Home;